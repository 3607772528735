import React from "react";
import styled from "styled-components";

const Job = props => (
  <div className="job-tile">
    <a href={props.url}>
      <img className="job-image" src={props.image} style={{ maxWidth: '540px' }} />
      <p className="job-type">{props.jobType}</p>
      <h3 className="job-title">{props.title}</h3>
      <h4 className="job-description">{props.text}</h4>
    </a>
  </div>
);

export default Job;
